<template lang="pug">
  div(style="max-width: 540px; margin: auto")
    h1 {{$t('characters.title')}}
    .py-2

    template(v-for="unitProfile, i in Object.values($db.unitProfiles).sort((a, b) => a.seq - b.seq)")
      Unit(:unit="unitProfile.unit")
      
</template>

<script>
import Unit from './Unit';

export default {
  name: 'Characters',

  components: { Unit },
};
</script>
