<template lang="pug">
  v-list-item(:to="{ path: `/character/${id}`, query: $router.query }", :replace="$vuetify.breakpoint.mdAndUp && $route.name == 'character'")
    v-list-item-avatar
      v-img(:src="require(`@/sprites/chr_ts_${id}.png`)")
    v-list-item-content
      v-list-item-title {{character.firstName}}{{character.givenName}}
      v-list-item-subtitle(v-if="$profile.user")
        | {{$t('characters.characterRank')}} 
        span.primary--text.float-right {{$profile.userCharacters.find(character => character.characterId == id).characterRank}}
      v-list-item-subtitle(v-if="$profile.user")
        | {{$t('characters.characterStage')}} 
        span.primary--text.float-right {{$profile.userChallengeLiveSoloStages.filter(stage => stage.characterId == id).map(stage => stage.rank).reduce((x, y) => x > y ? x : y, 0)}}

</template>

<script>
export default {
  name: 'Character',

  props: ['id'],

  computed: {
    character() {
      return this.$db.gameCharacters[this.id];
    }
  }
};
</script>