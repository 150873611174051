<template lang="pug">
  div
    h3 {{unitProfile.unitName}}

    Divider
    v-list.py-0(dense)
      template(v-for="_, i in Math.ceil(characters.length / rowSize)")
        Divider(v-if="i")
        .d-flex
          template(v-for="_, j in rowSize")
            v-divider(vertical, v-if="j")

            div(:style="{width: `calc((100% - ${rowSize-1}px) / ${rowSize})`}")
              template(v-if="i*rowSize+j < characters.length")
                Character(:id="characters[i*rowSize+j].id")


    Divider
    //- v-list-item(dense)
    //-   v-list-item-action-text.text-wrap {{unitProfile}}
    .py-2

</template>

<script>
import Character from './Character';

export default {
  name: 'Unit',

  props: ['unit'],

  components: { Character },

  computed: {
    unitProfile() {
      return this.$db.unitProfiles[this.unit];
    },
    characters() {
      return Object.values(this.$db.gameCharacters).filter(character => character.unit == this.unit);
    },
    rowSize() {
      return 2;
    }
  }
};
</script>